import { Typography } from "@mui/material";
import React from "react";

export function B() {
  return (
    <>
      <br />
      &emsp;&emsp;
    </>
  );
}
