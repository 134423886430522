import React from "react";

import "react-pdf/dist/esm/Page/TextLayer.css";

export function BtvRulesPage() {
  return (
    <div className="App">
      <a href={"/BeyondTheVeil.pdf"} target="_blank">
        Rules Pdf
      </a>
    </div>
  );
}
